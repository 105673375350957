var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pd-20"},[_c('a-page-header',{staticStyle:{"padding":"0"},attrs:{"sub-title":""}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('h1',[_c('i',{staticClass:"fas fa-barcode gradient"}),_vm._v(" BOLETOS")])]),_vm._v(" "),_c('div',{attrs:{"slot":"extra"},slot:"extra"},[_c('a-button',{attrs:{"type":"primary","ghost":""},on:{"click":function($event){_vm.openCreateBillingTicket = true}}},[_c('i',{staticClass:"fas fa-barcode mr-10"}),_vm._v(" NOVO BOLETO\n      ")])],1)]),_vm._v(" "),_c('a-collapse',{staticClass:"travel-filters expandable mb-10",attrs:{"activeKey":"0","expandIconPosition":"right"}},[_c('a-collapse-panel',{key:"1"},[_c('template',{slot:"header"},[_c('a-icon',{staticClass:"mr-5",attrs:{"type":"filter"}}),_vm._v(" FILTRAR\n      ")],1),_vm._v(" "),_c('a-row',{staticClass:"mt-0",attrs:{"gutter":20}},[_c('a-col',{attrs:{"span":6}},[_c('div',{staticClass:"travel-input"},[_c('label',{staticClass:"filled"},[_vm._v("Nome do boleto")]),_vm._v(" "),_c('a-input',{attrs:{"placeholder":"Escreva o nome"},on:{"change":function($event){return _vm.getBillingTickets()}},model:{value:(_vm.billingTickets.filters.searchTerm),callback:function ($$v) {_vm.$set(_vm.billingTickets.filters, "searchTerm", $$v)},expression:"billingTickets.filters.searchTerm"}})],1)])],1)],2)],1),_vm._v(" "),_c('a-table',{staticClass:"travel-table",attrs:{"columns":[
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        class: 'id',
        sorter: true,
      },
      {
        title: 'Nome ',
        dataIndex: 'name',
        key: 'name',
        scopedSlots: { customRender: 'name' },
      },

      {
        title: 'Empresa',
        dataIndex: 'company',
        key: 'company',
        scopedSlots: { customRender: 'company' },
      },
      {
        title: 'Banco',
        dataIndex: 'bank',
        key: 'bank',
        scopedSlots: { customRender: 'bank' },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        scopedSlots: { customRender: 'status' },
      },
      {
        title: 'Data/Hora',
        dataIndex: 'created',
        key: 'created',
        sorter: true,
      },
      {
        title: '',
        key: 'action',
        align: 'right',
        scopedSlots: { customRender: 'action' },
      },
    ],"data-source":_vm.billingTickets.list,"loading":_vm.billingTickets.loading,"pagination":false,"scroll":{ x: 1300 }},on:{"change":_vm.billingTicketsTableChange},scopedSlots:_vm._u([{key:"id",fn:function(text){return _c('a',{},[_vm._v(" "+_vm._s(text))])}},{key:"company",fn:function(record){return _c('div',{},[_vm._v("\n      "+_vm._s(record.trading_name)+"\n    ")])}},{key:"bank",fn:function(record){return _c('div',{},[_vm._v("\n      "+_vm._s(record.name)+"\n    ")])}},{key:"action",fn:function(record){return [_c('div',{staticClass:"actions-outer",staticStyle:{"position":"relative"}},[_c('a-tooltip',{attrs:{"placement":"top","title":"Editar"}},[_c('a',{staticClass:"edit ml-15",on:{"click":function($event){return _vm.edit(record.id)}}},[_c('a-icon',{attrs:{"type":"edit-svg"}})],1)])],1)]}}])}),_vm._v(" "),_c('div',{staticClass:"a-center mt-30",staticStyle:{"padding-bottom":"100px"}},[_c('a-pagination',{attrs:{"show-size-changer":"","default-current":_vm.billingTickets.pagination.page,"total":_vm.billingTickets.pagination.total},on:{"change":_vm.changeBillingTicketsPage,"showSizeChange":_vm.changeBillingTicketsPageSize}})],1),_vm._v(" "),_c('a-drawer',{attrs:{"placement":"right","width":"640px","closable":true,"visible":_vm.openCreateBillingTicket},on:{"close":function($event){_vm.openCreateBillingTicket = false}}},[_c('template',{slot:"title"},[_c('i',{staticClass:"fas fa-barcode cprimary mr-5"}),_vm._v(" NOVO BOLETO\n    ")]),_vm._v(" "),(_vm.openCreateBillingTicket)?_c('EditBillingTicketDefaultData',{on:{"listBillingTickets":_vm.listBillingTickets}}):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }