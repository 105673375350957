<template>
  <div class="pd-20">
    <a-page-header style="padding: 0" sub-title="">
      <div slot="title">
        <h1><i class="fas fa-barcode gradient"></i> BOLETOS</h1>
      </div>
      <div slot="extra">
        <a-button type="primary" ghost @click="openCreateBillingTicket = true">
          <i class="fas fa-barcode mr-10"></i> NOVO BOLETO
        </a-button>
      </div>
    </a-page-header>

    <a-collapse
      class="travel-filters expandable mb-10"
      activeKey="0"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template slot="header">
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>
        <a-row class="mt-0" :gutter="20">
          <a-col :span="6">
            <div class="travel-input">
              <label class="filled">Nome do boleto</label>
              <a-input
                placeholder="Escreva o nome"
                v-model="billingTickets.filters.searchTerm"
                @change="getBillingTickets()"
              />
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>

    <a-table
      class="travel-table"
      :columns="[
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          class: 'id',
          sorter: true,
        },
        {
          title: 'Nome ',
          dataIndex: 'name',
          key: 'name',
          scopedSlots: { customRender: 'name' },
        },

        {
          title: 'Empresa',
          dataIndex: 'company',
          key: 'company',
          scopedSlots: { customRender: 'company' },
        },
        {
          title: 'Banco',
          dataIndex: 'bank',
          key: 'bank',
          scopedSlots: { customRender: 'bank' },
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          scopedSlots: { customRender: 'status' },
        },
        {
          title: 'Data/Hora',
          dataIndex: 'created',
          key: 'created',
          sorter: true,
        },
        {
          title: '',
          key: 'action',
          align: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ]"
      :data-source="billingTickets.list"
      :loading="billingTickets.loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="billingTicketsTableChange"
    >
      <a slot="id" slot-scope="text"> {{ text }}</a>
      <div slot="company" slot-scope="record">
        {{ record.trading_name }}
      </div>
      <div slot="bank" slot-scope="record">
        {{ record.name }}
      </div>
      <template slot="action" slot-scope="record">
        <div class="actions-outer" style="position: relative">
          <a-tooltip placement="top" title="Editar">
            <a class="edit ml-15" @click="edit(record.id)">
              <a-icon type="edit-svg" />
            </a>
          </a-tooltip>
        </div>
      </template>
    </a-table>

    <div class="a-center mt-30" style="padding-bottom: 100px">
      <a-pagination
        show-size-changer
        :default-current="billingTickets.pagination.page"
        :total="billingTickets.pagination.total"
        @change="changeBillingTicketsPage"
        @showSizeChange="changeBillingTicketsPageSize"
      />
    </div>

    <a-drawer
      placement="right"
      width="640px"
      :closable="true"
      :visible="openCreateBillingTicket"
      @close="openCreateBillingTicket = false"
    >
      <template slot="title">
        <i class="fas fa-barcode cprimary mr-5"></i> NOVO BOLETO
      </template>
      <EditBillingTicketDefaultData
        v-if="openCreateBillingTicket"
        @listBillingTickets="listBillingTickets"
      />
    </a-drawer>
  </div>
</template>

<script>
import EditBillingTicketDefaultData from "@/components/billing-tickets/forms/EditBillingTicketDefaultData.vue";
import billingTicketsMixins from "@/mixins/billing-tickets/billingTicketsMixins.js";

export default {
  name: "ListBillingTickets",
  components: { EditBillingTicketDefaultData },
  mixins: [billingTicketsMixins],
  data() {
    return {
      openCreateBillingTicket: false,
    };
  },
  beforeMount() {
    this.getBillingTickets();
  },
  methods: {
    listBillingTickets() {
      this.getBillingTickets();
      this.openCreateBillingTicket = false;
    },
    edit(id) {
      this.$router.push(`edit/${id}`);
    },
  },
};
</script>
